import axios from 'axios'
import {GetCurrentUser} from './DefaultFunctions'

let user = GetCurrentUser()

export const BASE_DOMAIN = (() => {
  if (window.location.hostname === 'corm.3em.tech' || window.location.hostname === 'localhost')
    return 'http://api.corm.3em.tech/api/'
  // return "http://10.21.28.99:8086/api/";
  // return "http://localhost:5001/api/";
  // return "http://159.69.72.39:5001";
  // else return "http://10.21.28.99:8086/api/"
  // return 'http://apiuatcardcapture.samba.com.pk:8080/api/'
  // return "http://api.corm.3em.tech/api/"
  //  else return 'http://10.21.28.129:8086/api/'
  else return 'https://apicorm.samba.com.pk/api/'
})()

export const BASE_URL = BASE_DOMAIN
export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  httpAgent: true,
  // headers: {
  //     Authorization: `Bearer ${user.api_token}`,
  // },
})

// Add an interceptor to set the Authorization header for authenticated requests
API.interceptors.request.use((config) => {
  const user = GetCurrentUser()

  if (user && user.api_token) {
    config.headers.Authorization = `Bearer ${user.api_token}`
  }

  return config
})

// Add another interceptor to exclude the Authorization header for certain requests
API.interceptors.request.use((config) => {
  const excludeAuthHeader = config.excludeAuthHeader

  if (excludeAuthHeader) {
    delete config.headers.Authorization
  }

  return config
})

// // Define a login function that sends a request without the Authorization header
// export const loginUser = (username, password) => {
//   return API.post('/login', {username, password}, {excludeAuthHeader: true})
// }
